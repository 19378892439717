import { httpPost, httpDownload } from '@/utils/HttpUtil';
import { AxiosPromise } from 'axios';

/**
* ユーザーIDとパスワードより、システムをログインする。
*/
export const login = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/Login', data);
};

/**
* 新パスワードより、システムログインしたユーザーIDのパスワードを変更する。
*/
export const passwordChange = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/PasswordChange', data);
};

/**
* 入力された郵便番号から住所を取得して、提供する。

*/
export const addressSet = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uPostalcodeMaster/AddressSet', data);
};

/**
* 入力された検索条件より、ユーザー情報を検索し、一覧で表示する。
*/
export const userInfoSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserInfoSearch', data);
};

/**
* ユーザーIDより、ユーザー情報を削除する。
*/
export const userInfoDelete = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserInfoDelete', data);
};

/**
* 入力されたユーザー情報より、ユーザー情報を更新する。
*/
export const userDetailInfoUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserDetailInfoUpdate', data);
};

/**
* 入力されたユーザー情報より、ユーザー情報を新規し、登録する。
*/
export const userInfoRegister = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserInfoRegister', data);
};

/**
* ユーザー詳細画面の初期表示で、ユーザーIDより、ユーザー詳細情報を取得する。
*/
export const userDetailPageInit = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserDetailPageInit', data);
};

/**
* 設定ファイルの内容を編集し、ストレージ上のファイルに保存する。
*/
export const setFileSave = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSetFile/SetFileSave', data);
};

/**
* 代表ユーザーIDの利用停止申請を安田倉庫担当者のメールアドレスへ送信する。
*/
export const userStopRequest = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserStopRequest', data);
};

/**
* 安田倉庫担当者は荷主のユーザーIDの申請・変更・利用停止申請を確認して、承認者のメールアドレスへ送信する。
*/
export const userRequestConfirm = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserRequestConfirm', data);
};

/**
* 安田倉庫管理者は荷主のユーザーIDの利用停止申請を承認する。
*/
export const userStopRequestResister = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserStopRequestResister', data);
};

/**
* 代表ユーザーID申請を荷主のメールアドレスへ送信する。
*/
export const userIdRequestSend = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserIdRequestSend', data);
};

/**
* 荷主は管理者ID申請利用規約書をダウンロードする。
*/
export const pdfDownLoad = (data: FormData):AxiosPromise<any> => {
  return httpDownload('/uPdfDownLoad/PdfDownLoad', data);
};

/**
* 荷主はユーザー情報を入力して、代表ユーザーID申請を安田倉庫担当者のメールアドレスへメール送信する。
*/
export const userIdRequest = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserIdRequest', data);
};

/**
* 安田倉庫管理者は荷主の代表ユーザーID申請を承認する。
*/
export const userIdResisterSend = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserIdResisterSend', data);
};

/**
* 代表ユーザー変更情報を送信する。
*/
export const managerUserUpdateSend = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/ManagerUserUpdateSend', data);
};

/**
* 代表ユーザー変更情報を承認する。
*/
export const managerUserUpdateApprove = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/ManagerUserUpdateApprove', data);
};

/**
* 登録済みのユーザー情報（管理者、一般）の検索と照会を行う。
*/
export const userMasterInfoSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserMasterInfoSearch', data);
};

/**
* 検索されたユーザー情報（管理者、一般）より、データをCSVファイルに出力して、ダウンロードする。
*/
export const userMasterInfoDownLoad = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserMasterInfoDownLoad', data);
};

/**
* ログインユーザーIDより、営業所と寄託者リストを取得する。
*/
export const officeDepositorInfoSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/OfficeDepositorInfoSearch', data);
};

/**
* ログインユーザーのメールアドレスにパスワードリセットリクエストを送信する。
*/
export const passwordResetSend = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/PasswordResetSend', data);
};

/**
* ログイン画面で入力されたユーザーIDより、メールアドレスを検索する。
*/
export const mailAddressSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/MailAddressSearch', data);
};

/**
* 管理者・代表ユーザーのパスワードをリセットする。
*/
export const resetPassword = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/ResetPassword', data);
};

/**
* 設定ファイル画面を初期表示する。
*/
export const setFilePageInit = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSetFile/SetFilePageInit', data);
};

/**
* パスワードリセット（メール送信）画面を初期表示する。
*/
export const passwordResetSendPageInit = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/PasswordResetSendPageInit', data);
};

/**
* 設定ファイルの内容を取得する。
*/
export const setFilePageTxt = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSetFile/SetFilePageTxtGet', data);
};

/**
* 利用規約の内容を取得する。
*/
export const ruleTxtGet = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSetFile/RuleTxtGet', data);
};

/**
* メール中身のURLからセッションデータ保持IDより、セッションデータを取得する。
*/
export const sessionIDGet = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSession/SessionIDGet', data);
};

/**
* 画面選択された営業所と寄託者より、前回営業所コードと前回寄託者コードを更新する。
*/
export const depositorInfoDecide = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/DepositorInfoDecide', data);
};

/**
* 注意事項とお知らせ事項内容を取得する。
*/
export const infoTxtGet = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uSetFile/infoTxtGet', data);
};

/**
* 検索条件より、全ての寄託者ユーザーを一覧で取得する。ユーザー区分より、代表ユーザーが一段階目で、管理ユーザーと一般ユーザーは二段階で設定する。
*/
export const depositorInfoSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/DepositorInfoSearch', data);
};

/**
* 画面で選択されたユーザーIDより、営業所と寄託者の詳細情報を取得し、画面に表示する。
*/
export const depositorUserInfoPageInit = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/DepositorUserInfoPageInit', data);
};

/**
* 画面入力された営業所、寄託者と安田担当者メールアドレス情報を変更する。
*/
export const depositorInfoUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/DepositorInfoUpdate', data);
};

/**
* 入力されたユーザー情報をDBに更新して変更する。
*/
export const managerUserUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/ManagerUserUpdate', data);
};

/**
* システムをログアウトする。
*/
export const logout = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/Logout', data);
};

/**
* 運用マニュアルをPDF出力する。
*/
export const helpPDFShow = (data: FormData):AxiosPromise<any> => {
  return httpDownload('/uHelper/HelpPDFShow', data);
};

/**
* 入力されたユーザー情報より、ユーザー利用可能機能情報を更新する。
*/
export const userFunctionInfoUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uUserMaster/UserFunctionInfoUpdate', data);
};

/**
* 入力された在庫照会条件、在庫状態選択により、在庫を照会する。
*/
export const stockInquirySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/StockInquirySelect', data);
};

/**
* アップロードされた管理番号の文書箱情報を照会する。
*/
export const stockInfoSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/StockInfoSelect', data);
};

/**
* 貸出出庫の機能で、管理番号をキーに在庫を照会する。
*/
export const lendIssueSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/LendIssueSelect', data);
};

/**
* 貸出出庫画面にユーザー情報を初期表示する。
*/
export const defaultDisplaySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DefaultDisplaySelect', data);
};

/**
* 貸出出庫画面にユーザー情報を初期表示する。
*/
export const defaultCustomerDisplaySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DefaultCustomerDisplaySelect', data);
};


/**
* 貸出出庫画面で文書選択ボタンを押下し在庫を照会する。
*/
export const lendStockInquirySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/LendStockInquirySelect', data);
};

/**
* 貸出出庫指示を行う。
*/
export const lendOrderSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/LendOrderSelect', data);
};

/**
* 戻し入庫画面で文書選択ボタンを押下し在庫を照会する。
*/
export const returnStockInquirySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ReturnStockInquirySelect', data);
};

/**
* 出庫指示画面で文書選択ボタンを押下し在庫を照会する。
*/
export const issueStockInquirySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/IssueStockInquirySelect', data);
};

/**
* 廃棄指示、閲覧指示画面で文書選択ボタンを押下し在庫を照会する。
*/
export const discardStockInquirySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DiscardStockInquirySelect', data);
};

/**
* 戻し入庫指示を行う。
*/
export const returnOrderSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ReturnOrderSelect', data);
};

/**
* 出庫指示を行う。
*/
export const issueOrderSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/IssueOrderSelect', data);
};

/**
* 廃棄指示を行う。
*/
export const discardOrderSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DiscardOrderSelect', data);
};

/**
* 閲覧指示を行う。
*/
export const viewOrderSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ViewOrderSelect', data);
};

/**
* 文書変更の照会を行う。
*/
export const documentChangeSubmitSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DocumentChangeSubmitSelect', data);
};

/**
* 文書変更の入力を行う。
*/
export const documentChangeInputSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DocumentChangeInputSelect', data);
};

/**
* 明細を表示する
*/
export const detailDisplaySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DetailDisplaySelect', data);
};

/**
* 明細を編集する
*/
export const detailEditUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DetailEditUpdate', data);
};

/**
* 管理者ユーザーIDを変更する
*/
export const userIdChangeUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UserIdChangeUpdate', data);
};

/**
* 管理者ユーザーIDを更新する
*/
export const userIdUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UserIdUpdate', data);
};

/**
* ユーザー情報を更新する(管理者ユーザー)
*/
export const userInfoUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UserInfoUpdate', data);
};

/**
* ユーザー情報を更新する(一般ユーザー)
*/
export const userAvailableUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UserAvailableUpdate', data);
};

/**
* 管理者ユーザーIDを更新する
*/
export const depositorDataUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DepositorInfoUpdate', data);
};

/**
* 明細を登録する
*/
export const detailRegistInsert = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DetailRegistInsert', data);
};

/**
* 明細を登録する(文書明細アップロード)
*/
export const documentUploadInsert = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DocumentUploadInsert', data);
};

/**
 * 管理番号をキーに入出庫履歴を照会する。
 */
export const historyInquiryIssueSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/HistoryInquiryIssueSelect', data);
};

/**
 * 履歴照会画面に文書情報を表示する。 
 */
export const historyDefaultDisplaySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/HistoryDefaultDisplaySelect', data);
};

/**
 * 管理番号をキーにオーダー履歴を照会する。
 */
export const orderHistoryInquiryIssueSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/OrderHistoryInquirySelect', data);
};

/**
 * オーダー履歴参照画面に管理番号と文書名を表示する。 
 */
export const orderHistoryDefaultDisplaySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/OrderHistoryDefaultDisplaySelect', data);
};

/**
 * 検索されたオーダー履歴情報をCSVファイルに出力し、ダウンロードする。
 */
export const orderHistoryInfoDownLoad = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/OrderHistoryInfoDownLoad', data);
};

/**
 * 検索された在庫情報をCSVファイルに出力し、ダウンロードする。
 */
export const stockInfoDownLoad = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/StockInfoDownLoad', data);
};
/** 
* 明細を新規登録する
*/
export const newDetailRegistInsert = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/NewDetailRegistInsert', data);
};

/**
* 文書箱発注を行う
*/
export const documentBoxOrderSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DocumentBoxOrderSelect', data);
};

/**
* 連絡事項を送信する
*/
export const contactDetailOrder = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ContactDetailOrder', data);
};

/**
*  ご承認待ち一覧の照会を行う
*/
export const approveStandbySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ApproveStandbySelect', data);
};

/**
*  ご承認待ち一覧(要承認)の照会を行う
*/
export const essentitalApproveStandbySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/EssentialApproveStandbySelect', data);
};

/**
*  再入力一覧(要承認)の照会を行う
*/
export const requireApproveStandbySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RequireApproveStandbySelect', data);
};

/**
*  再入力一覧(要承認、ユーザー情報)の照会を行う
*/
export const requireUserInfoSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RequireUserInfoSelect', data);
};

/**
*  ご承認待ち一覧の選択をする*/
export const approveStandbyChoiceSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ApproveStandbyChoiceSelect', data);
};

/**
*  承認・却下指示を行う*/
export const approveStandbyOrder = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ApproveStandbyOrder', data);
};

/**
*  文書変更を行う
*/
export const documentChangeOrder = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DocumentChangeOrder', data);
};

/**
*  新規入庫指示を行う
*/
export const newInquiryOrder = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/NewInquiryOrder', data);
};

/**
*  新規入庫ラベル印刷を行う
*/
export const newInquiryLabelPrint = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/NewInquiryLabelPrint', data);
};

/** 
*  文書箱発注(再入力)情報を照会する。
*/
export const reEnterDocumentBoxOrder = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ReEnterDocumentBoxOrderSelect', data);
};

/**
*  文書変更(再入力)情報を照会する。
*/
export const reEnterDocumentChange = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ReEnterDocumentChangeSelect', data);
};

/**
*  文書変更後(再入力)情報を照会する。
*/
export const reEnterDocumentChangeAfter = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ReEnterDocumentChangeAfterSelect', data);
};

/**
*  オーダー履歴参照画面で在庫を照会する。
*/
export const orderStockInquirySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/OrderStockInquirySelect', data);
};

/**
*  ユーザー登録情報を照会する。
*/
export const userRegistChangeSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UserRegistChangeSelect', data);
};

/**
*  ユーザー登録情報を照会する。(代表ユーザー向け)
*/
export const representativeUserInfoChangeSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RepresentativeUserInfoChangeSelect', data);
};

/**
*  代表ユーザー登録情報を照会する。
*/
export const representativeUserChangeSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UserRegistChangeSelect', data);
};

/**
*  承認者情報を照会する。
*/
export const approverChangeSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ApproverChangeSelect', data);
};

/**
*  承認者情報を変更する。
*/
export const approverChangeOrder = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ApproverChangeOrder', data);
};

/**
*  ユーザー登録を行う
*/
export const userRegistOrder = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UserRegistOrder', data);
};

/**
*  ユーザー登録変更を行う
*/
export const userRegistChangeOrder = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UserRegistChangeOrder', data);
};

/**
*  機能設定を行う
*/
export const functionSettingOrder = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/FunctionSettingOrder', data);
};

/**
*  担当部署情報を照会する
*/
export const manageDepartmentSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ManageDepartmentSelect', data);
};

/**
*  利用可能部署を照会する
*/
export const enabledDepartmentSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/EnabledDepartmentSelect', data);
};

/**
*  承認者情報を照会する
*/
export const approverDisplaySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ApproverDisplaySelect', data);
};

/**
*  機能設定情報を照会する
*/
export const functionDisplaySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/FunctionDisplaySelect', data);
};

/**
*  担当部署設定を行う
*/
export const manageDepartmentOrder = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ManageDepartmentOrder', data);
};

/**
*  顧客利用部署登録の寄託者情報を照会する
*/
export const customerUseDepositorSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/CustomerUseDepositorSelect', data);
};

/**
*  顧客利用部署登録を行う(顧客マスタ)
*/
export const customerDepartmentInsert = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/CustomerDepartmentInsert', data);
};

/**
*  利用寄託者登録を行う
*/
export const useDepositorInsert = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UseDepositorInsert', data);
};

/**
*  顧客利用部署登録を行う(部署マスタ)
*/
export const departmentInsert = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DepartmentInsert', data);
};

/**
*  顧客利用部署登録を行う(顧客部署関連マスタ)
*/
export const customerDepartmentConnect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/CustomerDepartmentConnectInsert', data);
};

/**
*  代表ユーザーID登録申請を行う
*/
export const representativeUserRegistOrder = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RepresentativeUserRegistOrder', data);
};

/**
* 安田倉庫担当者は代表ユーザーID申請を確認し管理者へ承認依頼を行う。
*/
export const representativeUserRequestConfirm = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RepresentativeUserRequestConfirm', data);
};

/**
* 安田倉庫管理者は代表ユーザーID申請を承認する。
*/
export const representativeUserRequestApprove = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RepresentativeUserRequestApprove', data);
};
  /** 
  * 代表ユーザー登録準備画面で該当の会社名を表示する
*/
export const customerNmDisplaySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/CustomerNmDisplaySelect', data);
};

  /** 
  *  
  * 会社名の存在チェックを行う
*/
export const customerNmCheckSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/CustomerNmCheckSelect', data);
};

/**
*  代表ユーザー登録準備画面で利用可能部署を表示する
*/
export const departmentDisplaySelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/DepartmentDisplaySelect', data);
};

/**
*  代表ユーザー登録準備画面でユーザー情報を仮登録する
*/
export const userInfoInsert = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UserInfoInsert', data);
};

/**
*  顧客利用部署変更を行う
*/
export const userDepositorUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UserDepositorUpdate', data);
};

/**
*  顧客利用部署変更完了メールを送信する
*/
export const userDepositorChange = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UserDepositorChange', data);
};

/**
*  利用寄託者情報を更新する
*/
export const userDepositorListUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UserDepositorListUpdate', data);
};

/**
*  顧客利用部署関連マスタを更新する
*/
export const connectionDepositorUpdate = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/ConnectionDepositorUpdate', data);
};

/**
*  代表ユーザー登録準備確認画面から承認メールを送信する
*/
export const userInfoCheck = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/UserInfoCheck', data);
};

/**
*  代表ユーザー登録申請確認画面でユーザー情報を表示する
*/
export const representativeUserDefaultDisplay = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RepresentativeUserDefaultDisplay', data);
};
/** 
*  担当者メールアドレスを登録する
*/
export const mngMailAddInsert = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/MngMailAddInsert', data);
};

/**
*  現時点の承認者を照会する
*/
export const approverStatusSelect = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/MngMailAddInsert', data);
};

// /**
// *  代表ユーザー登録申請確認画面でユーザー情報を表示する
// */
// export const representativeUserDefaultDisplay = (data: FormData):AxiosPromise<any> => {
//   return httpPost('/uWebDocment/RepresentativeUserDefaultDisplay', data);
// };

/**
*  代表ユーザー変更申請を行う
*/
export const representativeUserChange = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RepresentativeUserChange', data);
};

/**
*  代表ユーザー変更申請内容の確認を行い、営業所管理者に承認依頼を送信する
*/
export const representativeUserChangeRequestConfirm = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RepresentativeUserChangeConfirm', data);
};

/**
*  代表ユーザー変更申請内容の初期表示を行う
*/
export const representativeUserChangeDefaultDisplay = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RepresentativeUserChangeDefaltDisplay', data);
};

/**
*  代表ユーザー変更申請の承認を行う
*/
export const representativeUserChangeRequestApprove = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RepresentativeUserChangeApprove', data);
};

/**
*  代表ユーザーの利用停止申請を行う
*/
export const representativeUserDisable = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RepresentativeUserDisable', data);
};

/**
*  代表ユーザーの利用停止申請の確認を行う
*/
export const representativeUserDisableConfirm = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RepresentativeUserDisableConfirm', data);
};

/**
*  代表ユーザーの利用停止申請の承認を行う
*/
export const representativeUserDisableApprove = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/RepresentativeUserDisableApprove', data);
};

/**
* 入力したファイル名により、ファイルをダウンロードする。
*/
export const fileDownload = (data: FormData, type: string):AxiosPromise<any> => {
  if(type === "csv"){
    return httpPost('/uFile/fileDownload', data);
  }else if(type === "pdf"){
    return httpDownload('/uFile/fileDownload', data);
  }
};

/**
*  確認・承認をチェックする
*/
export const checkConfirm = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/CheckConfirm', data);
};

/**
*  既存の管理番号をチェックする
*/
export const existMgmtNumCheck = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/MgmtNumCheck', data);
};

/**
*  営業日を検索する
*/
export const businessDateSearch = (data: FormData):AxiosPromise<any> => {
  return httpPost('/uWebDocment/BusinessDateSearch', data);
};
